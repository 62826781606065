import type { Attribute } from '@commercetools/platform-sdk'

import { EMPTY_VALUE } from 'config/const'

function getAttributeValue(key: string, attributes?: ReadonlyArray<Attribute>) {
  const attribute = attributes?.find((a) => a.name === key)
  return attribute?.value ?? EMPTY_VALUE
}

export default getAttributeValue
