import type { TypedMoney, Price, PriceTier } from '@commercetools/platform-sdk'
import { useMemo } from 'react'

import { DEFAULT_CURRENCY } from 'config/i18n.config'
import getTierPriceValueByQuantity from 'utils/getTierPriceValueByQuantity'
import hasProperty from 'utils/hasProperty'
import useBlanketAgreements from 'utils/Providers/BlanketAgreements/useBlanketAgreements'

type AcceptedValue = TypedMoney | Price | ReadonlyArray<Price> | undefined

type UserPriceDefined = readonly [priceValue: number, currencyCode: string]
type UserPriceUndefined = null
type UserPrice = UserPriceDefined | UserPriceUndefined

function getTypedMoney(money: TypedMoney): UserPriceDefined {
  return [money.centAmount / 100, money.currencyCode]
}

function isTypedMoney(value: unknown): value is TypedMoney {
  return hasProperty(value, 'currencyCode')
}

function hasTiersPrices(value: unknown): value is { tiers: PriceTier[] } {
  return hasProperty(value, 'tiers')
}

function isPrice(value: unknown): value is Price {
  return hasProperty(value, 'value')
}

function isPriceArray(value: unknown): value is Price[] {
  return Array.isArray(value) && value.length > 0 && value.every(isPrice)
}

/**
 * Returns the price of a product in the user's currency.
 *
 * @example
 * ```ts
 * const money: TypedMoney = { type: 'centPrecision', centAmount: 12345, currencyCode: 'USD' }
 * const price = usePrice(money)
 * // price = [123.45, 'USD']
 * ```
 */
function usePrice(price: AcceptedValue, quantity?: number): UserPrice {
  const blanketAgreements = useBlanketAgreements()

  const USER_CURRENCY_CODE = blanketAgreements.data.currency || DEFAULT_CURRENCY

  return useMemo<UserPrice>(() => {
    if (!price) {
      return null
    }

    if (hasTiersPrices(price) && quantity) {
      const tierPrice = getTierPriceValueByQuantity(price.tiers, quantity)

      if (tierPrice) {
        return getTypedMoney(tierPrice.value)
      }
    }
    if (isTypedMoney(price)) {
      return getTypedMoney(price)
    }
    if (isPrice(price)) {
      return getTypedMoney(price.value)
    }
    if (isPriceArray(price)) {
      const blanketAgreementsPrice = price.find(
        (value) =>
          value?.customerGroup?.id === blanketAgreements.data.id && value.value.currencyCode === USER_CURRENCY_CODE,
      )
      const typedMoney: TypedMoney | undefined = blanketAgreementsPrice?.value

      if (!typedMoney) {
        return null
      }
      return getTypedMoney(typedMoney)
    }

    return null
  }, [USER_CURRENCY_CODE, quantity, blanketAgreements.data.id, price])
}

export default usePrice
