import { Box, Flex, FlexProps, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import type { CentPrecisionMoney, Price as PriceType, ProductProjection } from '@commercetools/platform-sdk'
import React, { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import Link from 'components/elements/Link'
import Price from 'components/elements/Price'
import getAttributeValue from 'components/modules/Product/utils/getAttributeValue'
import { DEFAULT_PRODUCT_IMAGE } from 'config/product'
import { RoutePaths } from 'config/routes'
import useLocalizedString from 'hooks/useLocalizedString'
import assignParamsToUrl from 'utils/assignParamsToUrl'

interface ProductsListItemProps extends FlexProps {
  // we use `productKey`, because we cannot use `key` as a prop name.
  // @see https://reactjs.org/warnings/special-props.html
  productKey: ProductProjection['key'] | undefined
  masterVariant: ProductProjection['masterVariant'] | undefined
  categories: ProductProjection['categories'] | undefined
  price?: PriceType | CentPrecisionMoney
  isLarge?: boolean
  hideImage?: boolean
  children?: React.ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'ProductLink'

const styles = {
  wrapper: {
    mr: { base: 0, md: 2 },
    w: 'full',
    alignItems: 'start',
    color: 'inherit',
    justifyContent: { base: 'space-between', md: 'flex-start' }
  },
  link: {
    color: 'primary.black',
    lineHeight: '1',
    fontWeight: 'bold',
  },
  heading: {
    justifyContent: 'space-between',
    mb: 2,
    maxW: { base: '225px', md: '240px' },
  },
} as const satisfies SystemStyleInterpolation

const ProductLink: FunctionComponent<ProductsListItemProps> = ({
  productKey,
  masterVariant,
  categories,
  price,
  isLarge,
  hideImage,
  children,
  ...props
}) => {
  const display = useLocalizedString()

  const href = assignParamsToUrl(RoutePaths.productDetails, { slug: productKey })
  const productImage = masterVariant?.images?.[0]
  const categoryName = display(categories?.[0]?.obj?.name)
  const imageSize = isLarge ? 94 : 48
  const productImageUrl = productImage?.url || DEFAULT_PRODUCT_IMAGE
  const name = getAttributeValue('erpItemDescription', masterVariant?.attributes)

  const productName = display(name)

  return (
    <Flex
      aria-label={productName}
      data-testid={TAG}
      {...(!hideImage && { flexDirection: { base: 'row-reverse', md: 'row' } })}
      {...styles.wrapper}
      {...props}
    >
      <Link href={href} flexBasis={`${imageSize}px`} flexShrink="0" hidden={hideImage}>
        <Image src={productImageUrl} width={imageSize} height={imageSize} alt={productName} />
      </Link>
      <Box w="full" mx={{ base: 0, md: hideImage ? 0 : isLarge ? 4 : 2 }}>
        <Text>{categoryName}</Text>

        <Flex {...styles.heading}>
          <Link href={href} {...styles.link} rel="noreferrer" fontSize={isLarge ? 'lg' : 'md'} isTruncated>
            {productName}
          </Link>
          {price && <Price price={price} />}
        </Flex>

        {children}
      </Box>
    </Flex>
  )
}

ProductLink.displayName = TAG

export default ProductLink
