import { Box, BoxProps, Heading, HeadingProps, SimpleGrid, Text, TextProps } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

export const DT: FunctionComponent<HeadingProps> = ({ children, ...props }) => (
  <Heading as="dt" size="sm" {...props}>
    {children}
  </Heading>
)
export const DD: FunctionComponent<TextProps> = ({ children, ...props }) => (
  <Text as="dd" mb="4" _last={{ mb: '0' }} textAlign={{ base: 'right', sm: 'inherit' }} {...props}>
    {children}
  </Text>
)

export const DL: FunctionComponent<BoxProps> = ({ children, ...props }) => {
  return (
    <Box as="dl" mb="6" {...props}>
      {children}
    </Box>
  )
}

export const DLElementsWrapper: FunctionComponent<BoxProps> = ({ children, ...props }) => {
  return (
    <SimpleGrid as="dl" columns={[2, 1]} flex={1} px={{ md: '4' }} w="full" alignSelf="flex-start" {...props}>
      {children}
    </SimpleGrid>
  )
}
