import type { ShoppingList } from '@commercetools/platform-sdk'

import type { MyListsContextValue } from 'components/modules/ShoppingList/AddToShoppingList/MyListsProvider/context'

export const isInList = (list: ShoppingList, productId: string, variantId: number): boolean => {
  return list.lineItems.some((item) => item.productId === productId && item.variantId === variantId)
}

export const isInAllLists = (data: MyListsContextValue['data'], productId: string, variantId: number): boolean => {
  return data?.results?.some((list) => isInList(list, productId, variantId)) ?? false
}
