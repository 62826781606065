import { useDisclosure } from '@chakra-ui/react'
import noop from 'lodash/noop'
import dynamic from 'next/dynamic'
import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react'

import {
  AddToShoppingListButtonContext,
  AddToShoppingListButtonContextValue,
  ShowPopoverArguments,
} from 'components/modules/ShoppingList/AddToShoppingList/AddToListProvider/context'
import MyListsProvider from 'components/modules/ShoppingList/AddToShoppingList/MyListsProvider'

const AddToShoppingListModal = dynamic(() => import('components/modules/ShoppingList/AddToShoppingList/Modal'))

interface AddToShoppingListProviderProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'AddToShoppingListProvider'

/**
 * Add to shopping list provider.
 *
 * We don't want to fetch shopping lists on every "Add to list" button click,
 * so we use this provider to fetch shopping lists only once.
 */
const AddToShoppingListProvider: FunctionComponent<AddToShoppingListProviderProps> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [state, setState] = useState<ShowPopoverArguments>({
    showTrigger: noop,
    hideTrigger: noop,
    productId: '',
    quantity: 1,
    variantId: 0,
    erpWarehouse: '',
    erpFacility: '',
    ref: void 0,
  })

  const closeModal = useCallback(() => {
    onClose()
    state.hideTrigger()
  }, [onClose, state])

  const context = useMemo<AddToShoppingListButtonContextValue>(
    () => ({
      showModal(args: ShowPopoverArguments) {
        args.showTrigger()
        setState(args)
        onOpen()
      },
    }),
    [onOpen],
  )

  return (
    <MyListsProvider>
      <AddToShoppingListButtonContext.Provider value={context}>{children}</AddToShoppingListButtonContext.Provider>

      <AddToShoppingListModal
        finalFocusRef={state.ref}
        id={TAG}
        isOpen={isOpen}
        onClose={closeModal}
        data-testid={`${TAG}-modal`}
        productId={state.productId}
        quantity={state.quantity}
        variantId={state.variantId}
        erpFacility={state.erpFacility}
        erpWarehouse={state.erpWarehouse}
      />
    </MyListsProvider>
  )
}

AddToShoppingListProvider.displayName = TAG

export default AddToShoppingListProvider
