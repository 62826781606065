import {
  Box,
  BoxProps,
  InputGroup,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SystemStyleInterpolation,
  Text,
} from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import useTranslation from 'next-translate/useTranslation'
import { KeyboardEvent, useMemo, useRef } from 'react'

type ProductQuantityInputType = BoxProps & {
  unit?: string
  debouncedSetQuantity: (quantity: number) => void
  initialValue?: number
  minQuantity?: number
  maxQuantity?: number
  showLabel?: boolean
  isDisabled?: boolean
}

const TAG = 'ProductQuantityInput'

const styles = {
  numberInput: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px',
    borderColor: 'gray.300',
    pl: { base: '8', sm: '3' },
    pr: '8',
    py: '1',
    alignItems: { base: 'center', sm: 'flex-start' },
  },
  numberInputField: {
    fontSize: 'sm',
    pl: '0',
    // Added for units
    pr: '4',
    minH: '0',
    h: 'auto',
    border: 'none',
    minW: '10',
    maxW: 'full',
  },
  inputStepper: {
    width: { base: 'full', sm: '6' },
    flexDirection: { base: 'row-reverse', sm: 'column' },
    justifyContent: { base: 'space-between', sm: 'center' },
    pointerEvents: 'none',
  },
  inputStepperButton: {
    pointerEvents: 'auto',
    color: 'primary.blue',
    maxW: '40px',
    _first: {
      borderInlineStart: { base: 'none', sm: '1px solid' },
      borderInlineStartColor: { base: 'none', sm: 'gray.300' },
      marginTop: { base: '0', sm: '-1px' },
    },
    _last: {
      marginTop: '-1px',
      borderTopWidth: { base: '0', sm: '1px' },
    },
  },
} as const satisfies SystemStyleInterpolation

const preventNonDigits = (event: KeyboardEvent<HTMLInputElement>) => {
  if (isNaN(Number(event.key))) {
    event.preventDefault()
  }
}

const ProductQuantityInput = ({
  minQuantity = 1,
  maxQuantity,
  debouncedSetQuantity,
  showLabel = true,
  initialValue,
  unit,
  isDisabled,
  ...props
}: ProductQuantityInputType) => {
  const { t } = useTranslation('product')

  const unitRef = useRef<HTMLDivElement>(null)
  const _minQuantity = minQuantity > 0 ? minQuantity : 1
  const _maxQuantity = maxQuantity && maxQuantity > 0 ? maxQuantity : undefined
  const updateQuantityDebounced = useMemo(
    () => debounce((_: string, quantity: number) => debouncedSetQuantity(quantity), 500, { trailing: true }),
    [debouncedSetQuantity],
  )

  return (
    <Box data-testid={TAG} w={{ base: 'full', sm: 'auto' }} {...props}>
      <NumberInput
        data-testid="NumberInput"
        errorBorderColor="transparent"
        focusInputOnChange={false}
        min={_minQuantity}
        {...(_maxQuantity ? { max: _maxQuantity } : {})}
        defaultValue={initialValue || _minQuantity}
        onChange={updateQuantityDebounced}
        h={showLabel ? 12 : 10}
        justifyContent={{ base: 'center', md: showLabel ? 'flex-start' : 'center' }}
        {...styles.numberInput}
        isDisabled={isDisabled}
      >
        {showLabel && (
          <Text display={{ base: 'none', sm: 'block' }} fontWeight="bold" fontSize="xs">
            {t('components.detail.quantity')}
          </Text>
        )}

        <InputGroup maxW={unitRef.current?.offsetWidth ? `calc(61px + ${unitRef.current?.offsetWidth}px)` : '75px'}>
          <NumberInputField
            inputMode="numeric"
            min={minQuantity}
            _focus={{ border: 'none' }}
            onKeyPress={preventNonDigits}
            {...styles.numberInputField}
          />

          <InputRightElement ref={unitRef} h="5" w="auto">
            {unit}
          </InputRightElement>
        </InputGroup>

        <NumberInputStepper {...styles.inputStepper}>
          <NumberIncrementStepper {...styles.inputStepperButton} />
          <NumberDecrementStepper {...styles.inputStepperButton} />
        </NumberInputStepper>
      </NumberInput>
    </Box>
  )
}
export default ProductQuantityInput
