import { Box, Spinner, Text } from '@chakra-ui/react'
import type { ProductProjection } from '@commercetools/platform-sdk'
import { useQuery } from '@tanstack/react-query'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useMemo } from 'react'

import getLeadTimeAndDeliveryForm from 'azure/order/getLeadTimeAndDeliveryForm'
import { EMPTY_VALUE } from 'config/const'

const TAG = 'LeadTime'

interface LeadTimeProps {
  masterVariant: ProductProjection['masterVariant'] | undefined
  showDeliveryForm?: boolean
}

const LeadTime: FunctionComponent<LeadTimeProps> = ({ masterVariant }) => {
  const { t } = useTranslation()

  const { erpFacility, erpWarehouse } = masterVariant?.price?.custom?.fields || {}

  const erpTechspecDeliveryForm = useMemo(
    () => masterVariant?.attributes?.find((attr) => attr.name === 'erpTechspecDeliveryForm')?.value,
    [masterVariant],
  )

  const erpTechspecDiameterInInches = useMemo(
    () => masterVariant?.attributes?.find((attr) => attr.name === 'erpTechspecDiameterInInches')?.value,
    [masterVariant],
  )

  const isEnabled = !!erpTechspecDeliveryForm && !!erpFacility

  const { data, isLoading, isError } = useQuery(
    ['getLeadTimeAndDeliveryForm', erpFacility, erpTechspecDeliveryForm, erpWarehouse, erpTechspecDiameterInInches],
    getLeadTimeAndDeliveryForm,
    {
      enabled: isEnabled,
    },
  )

  if (isLoading && isEnabled) {
    return <Spinner boxSize="3" color="primary.blue" />
  }

  if (!masterVariant || !data || isError) {
    return <Text>{EMPTY_VALUE}</Text>
  }

  return (
    <Box>
      {data?.leadTimeWeeksFrom && data?.leadTimeWeeksTo
        ? t('components.cart.leadTime', { from: data?.leadTimeWeeksFrom, to: data?.leadTimeWeeksTo }, { default: '-' })
        : t('components.cart.leadTimeNotAvailable')}
    </Box>
  )
}

LeadTime.displayName = TAG

export default LeadTime
