import { Text } from '@chakra-ui/react'
import type { TypedMoney, Price as CommercetoolsPrice } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import { EMPTY_VALUE } from 'config/const'
import usePrice from 'hooks/usePrice'

type InheritedProps = React.ComponentProps<typeof Text>

type PriceProps = InheritedProps & {
  /**
   * The price of the product variant.
   */
  price: TypedMoney | CommercetoolsPrice | ReadonlyArray<CommercetoolsPrice> | undefined
  /**
   * The quantity of the product.
   * If passed, it will be used to get tier price.
   * @default 1
   */
  quantity?: number
  /**
   * If passed, it will be used to calculate total price.
   * @default false
   */
  showTotalPrice?: boolean
}

/**
 * Used for E2E tests.
 */
const TAG = 'Price'

const Price: FunctionComponent<PriceProps> = ({ price, quantity = 1, showTotalPrice = false, ...props }) => {
  const { lang } = useTranslation()

  const calculated = usePrice(price, quantity)

  if (calculated === null) {
    return <Text {...props}>{EMPTY_VALUE}</Text>
  }

  const [priceValue, currencyCode] = calculated
  const qty = quantity === 0 ? 1 : quantity
  const value = showTotalPrice ? qty * priceValue : priceValue
  const priceFormatted = new Intl.NumberFormat(lang, { style: 'currency', currency: currencyCode }).format(value)
  return <Text {...props}>{priceFormatted}</Text>
}

Price.displayName = TAG

export default Price
