import noop from 'lodash/noop'
import { createContext } from 'react'

export interface AddToListProduct {
  productId: string
  variantId: number
  quantity: number
  erpWarehouse: string | undefined
  erpFacility: string | undefined
}

export interface ShowPopoverArguments extends AddToListProduct {
  hideTrigger: VoidFunction
  showTrigger: VoidFunction
  ref: React.RefObject<HTMLElement> | undefined
}

export interface AddToShoppingListButtonContextValue {
  showModal: (params: ShowPopoverArguments) => void
}

const initialValueButton: AddToShoppingListButtonContextValue = {
  showModal: noop,
}

export const AddToShoppingListButtonContext = createContext<AddToShoppingListButtonContextValue>(initialValueButton)
