import { Button, Icon, SystemStyleObject } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { ComponentProps, FunctionComponent, useCallback, useContext, useRef, useState } from 'react'

import AddToShoppingListIcon from 'assets/add_list.svg?component'
import ButtonModal from 'components/elements/ButtonModal'
import { AddToShoppingListButtonContext } from 'components/modules/ShoppingList/AddToShoppingList/AddToListProvider/context'
import { MyListsContext } from 'components/modules/ShoppingList/AddToShoppingList/MyListsProvider/context'
import { isInAllLists } from 'components/modules/ShoppingList/AddToShoppingList/MyListsProvider/utils'

type InheritedProps = Omit<ComponentProps<typeof Button>, 'ref'>

interface AddToShoppingListButtonProps extends InheritedProps {
  productId: string
  variantId: number
  quantity: number
  erpFacility: string
  erpWarehouse: string
}

/**
 * Used for E2E tests.
 */
const TAG = 'AddToShoppingListButton'

const styles = {
  button: {
    variant: 'link',
    position: 'relative',
    sx: {
      '& [data-success="true"]': {
        transition: 'opacity 0.2s ease-in-out',
      },
      '&[data-is-in-list="true"] [data-success="true"]': {
        opacity: 1,
      },
    },
  },
} as const satisfies SystemStyleObject

/**
 * Add to shopping list button.
 *
 * @example
 * ```tsx
 * <AddToShoppingListProvider>
 *   <AddToShoppingListButton productId={id} variantId={variant.id} quantity={1} />
 * </AddToShoppingListProvider>
 * ```
 */
const AddToShoppingListButton: FunctionComponent<AddToShoppingListButtonProps> = ({
  productId,
  quantity,
  variantId,
  erpFacility,
  erpWarehouse,
  children,
  ...props
}) => {
  const { t } = useTranslation('shoppingList')
  const btnRef = useRef<HTMLButtonElement>(null)
  const [showTrigger, setShowTrigger] = useState(false)

  const { showModal } = useContext(AddToShoppingListButtonContext)

  const onClick = useCallback(() => {
    showModal({
      showTrigger: () => setShowTrigger(true),
      hideTrigger: () => setShowTrigger(false),
      productId,
      quantity,
      variantId,
      erpFacility,
      erpWarehouse,
      ref: btnRef,
    })
  }, [erpFacility, erpWarehouse, productId, quantity, showModal, variantId])

  const { data } = useContext(MyListsContext)

  return (
    <ButtonModal
      ref={btnRef}
      isOpen={showTrigger}
      onClick={onClick}
      testid={TAG}
      data-is-in-list={isInAllLists(data, productId, variantId)}
      aria-label={t('components.addToList.button')}
      leftIcon={<Icon aria-hidden="true" as={AddToShoppingListIcon} boxSize="5" />}
      {...styles.button}
      {...props}
    >
      {children}
    </ButtonModal>
  )
}

AddToShoppingListButton.displayName = TAG

export default AddToShoppingListButton
