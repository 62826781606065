import type { Cart, CartUpdateAction, ClientResponse } from '@commercetools/platform-sdk'

import { RoutePaths } from 'config/routes'
import apiAuthFetch from 'utils/apiAuthFetch'
import stringifyObjectValues from 'utils/stringifyObjectValues'

interface Variables {
  businessUnitKey: string
  cartId: string
  cartVersion: number
  actions: CartUpdateAction | ReadonlyArray<CartUpdateAction>
}

/**
 * Adds action to the cart.
 * @link https://docs.commercetools.com/api/projects/carts#add-lineitem
 */
function updateCart({ businessUnitKey, cartId, cartVersion, actions }: Variables): Promise<ClientResponse<Cart>> {
  const stringifiedParams = stringifyObjectValues({ businessUnitKey })
  const params = new URLSearchParams(stringifiedParams).toString()
  const url = `${RoutePaths.cartsApi}${cartId}?${params}`
  return apiAuthFetch<ClientResponse<Cart>>(url, {
    method: 'POST',
    body: JSON.stringify({ version: cartVersion, actions: Array.isArray(actions) ? actions : [actions] }),
  })
}

export default updateCart
