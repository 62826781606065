import { useToast } from '@chakra-ui/react'
import type { CartUpdateAction } from '@commercetools/platform-sdk'
import { useMutation } from '@tanstack/react-query'
import useTranslation from 'next-translate/useTranslation'
import { useCallback } from 'react'

import updateCart from 'commercetools/cart/updateCart'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'
import useCart from 'utils/Providers/Cart/useCart'

/**
 * Returns a function that can be used to submit the AddToCart form.
 */
function useCartAction() {
  const { mutateAsync, isLoading: isLoadingAddToCart } = useMutation(updateCart)

  const { t } = useTranslation()
  const toast = useToast()
  const { refetch: refetchCart, data: cart, isLoading: isLoadingCart, isError } = useCart()
  const { id: cartId, version: cartVersion } = cart || {}

  const {
    data: { key: businessUnitKey },
  } = useBusinessUnit()

  const onSubmit = useCallback(
    async (actions: CartUpdateAction | CartUpdateAction[]) => {
      if (!cartId || !cartVersion) {
        return
      }
      try {
        await mutateAsync({ businessUnitKey, cartId, cartVersion, actions })

        toast({
          title: t('components.cart.alerts.cartUpdated'),
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
        return await refetchCart()
      } catch (error) {
        toast({
          title: t('components.cart.alerts.cartCannotBeUpdated'),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    },
    [businessUnitKey, cartId, cartVersion, mutateAsync, refetchCart, t, toast],
  )

  return {
    onSubmit,
    isSubmitting: isLoadingCart || isLoadingAddToCart,
    isError,
  }
}

export default useCartAction
