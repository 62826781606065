import type { QueryFunctionContext } from '@tanstack/react-query'

import { fetchWithAzureToken } from 'azure/client/user'
import { AzureScopes } from 'config/auth'
import { RoutePaths } from 'config/routes'
import jsonOrEmptyTextMapper from 'utils/jsonOrEmptyTextMapper'
/**
 * @param {string} erpCustomerNumber - Erp customer number from business unit custom fields
 * @returns Delivery form and lead time
 */

type QueryKey = [
  queryFnName: string,
  facility: string,
  erpTechspecDeliveryForm: string,
  warehouse?: string,
  erpTechspecDiameterInInches?: string,
]

type OrderList = Pick<QueryFunctionContext<QueryKey>, 'queryKey'>

async function getLeadTimeAndDeliveryForm({
  queryKey: [, facility, erpTechspecDeliveryForm, warehouse, erpTechspecDiameterInInches],
}: OrderList): Promise<DeliveryFormResponse> {
  if (!erpTechspecDeliveryForm || !facility) {
    return Promise.reject(new Error('facility or delivery form is missing'))
  }

  const params = new URLSearchParams({
    facility,
    erpTechspecDeliveryForm,
    ...(warehouse && { warehouse }),
    ...(erpTechspecDiameterInInches && { erpTechspecDiameterInInches }),
  }).toString()

  const response = await fetchWithAzureToken(AzureScopes.Delivery, `${RoutePaths.deliveryApi}?${params}`)
  return await jsonOrEmptyTextMapper(response)
}

export default getLeadTimeAndDeliveryForm
