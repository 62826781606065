import { useQuery } from '@tanstack/react-query'
import React, { FunctionComponent, ReactNode } from 'react'

import getMyShoppingLists from 'commercetools/shoppingLists/getMyShoppingLists'
import { MyListsContext } from 'components/modules/ShoppingList/AddToShoppingList/MyListsProvider/context'
import useBlanketAgreements from 'utils/Providers/BlanketAgreements/useBlanketAgreements'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface MyListsProviderProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'MyListsProvider'

const MyListsProvider: FunctionComponent<MyListsProviderProps> = ({ children }) => {
  const businessUnit = useBusinessUnit()
  const blanketAgreements = useBlanketAgreements()

  const response = useQuery(
    ['getMyShoppingLists', businessUnit.data.key, blanketAgreements.data.id, blanketAgreements.data.currency],
    getMyShoppingLists,
    { enabled: !!businessUnit.data.key },
  )

  return <MyListsContext.Provider value={response}>{children}</MyListsContext.Provider>
}

MyListsProvider.displayName = TAG

export default MyListsProvider
